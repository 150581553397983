@import "~bootstrap/scss/bootstrap";

body {
  background-color: #1f1f1f;
}

p {
  color: #ffffff;
}

.countdown {
  color: #FFFFFF;
  font-size: 2rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #E0CCBE;
  font-family: "playfair display";
}

hr {
  border: 1px solid #E0CCBE;
  width: 80%;
}

.card {
  background-color: #282c34;
}

.header-link, a:link, a:visited {
  color: #E0CCBE;
  text-decoration: none;
}

a:hover {
  color: #E0CCBE;
  text-decoration: underline;
}

.header-link a:hover {
  color: #E0CCBE;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
